import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyCWMzwuE7uTu5nc5COzXgszf21WY-9Stu4",
  authDomain: "fotokryss.firebaseapp.com",
  databaseURL: "https://fotokryss.firebaseio.com",
  projectId: "fotokryss",
  storageBucket: "fotokryss.appspot.com",
  messagingSenderId: "413565163389",
  appId: "1:413565163389:web:2a4f89b8bc2ed5ff4ede14",
};

export default !firebase.apps.length
  ? firebase.initializeApp(config)
  : firebase.app();
