import React, { useState, useEffect } from "react";
import { Table, Row, Col } from "antd";
import "./App.css";
import firebase from "./Config";
import { Lightbox } from "react-modal-image";

function App() {
  const [data, setData] = useState([]);
  const [showLightbox, setShowLightbox] = useState(false);
  const [lightBoxURL, setLightboxURL] = useState("");

  const columns = [
    {
      title: "Art",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Latin",
      dataIndex: "latin",
      key: "latin",
    },
    {
      title: "Datum",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Bild",
      dataIndex: "image",
      key: "bild",
      render: (text) => (
        <div>
          {text != null && (
            <img
              onClick={(e) => clickImage(text)}
              src={text}
              style={{ width: "100px", cursor: "pointer" }}
            />
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const db = firebase.database();
    const rootRef = db.ref().child("/");
    const birdsRef = rootRef.child("birds");
    birdsRef.on("value", (snapshot) => {
      console.log(snapshot.val());
      var birds = [];
      var count = 1;
      var values = snapshot.val();
      for (var key in values) {
        console.log("KEY: " + key);
        birds[count] = values[key];
        birds[count]["key"] = count;
        count++;
      }
      console.log(birds);
      setData(birds);
    });
  }, []);

  function handleCancel() {
    setShowLightbox(false);
  }

  function clickImage(imageURL) {
    setShowLightbox(true);
    setLightboxURL(imageURL);
  }

  return (
    <div className="App">
      <h1 style={{ paddingTop: "1em" }}>Leo's Fotokryss</h1>
      <Row>
        <Col xs={0} sm={1} md={1} lg={4} xl={5} />
        <Col xs={24} sm={22} md={22} lg={16} xl={14}>
          <Table dataSource={data} columns={columns} />;
        </Col>
        <Col xs={0} sm={1} md={1} lg={4} xl={5} />
      </Row>
      {showLightbox && <Lightbox large={lightBoxURL} onClose={handleCancel} />}
    </div>
  );
}

export default App;
